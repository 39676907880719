import React, { Component } from "react";
import { base } from "../../base";
import db from "../../base";
import { Redirect } from "react-router-dom";
import AdminSignature from "./AdminSignature/AdminSignature";

/* TODO :
  1/ Récupérer le localstorage "isLoggedIn" et le stocker dans le state
  2/ Si le state "isLoggedIn" est false, Redirect sur la page Login
  3/ Si il est true, Render le component
*/

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mails: {},
      name: "",
      lastName: "",
      poste: "",
      isLoggedIn: false
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    base
      .collection("emails")
      .add({
        name: this.state.name,
        lastName: this.state.lastName,
        poste: this.state.poste
      })
      .then(ref => {
        this.setState({
          name: "",
          lastName: "",
          poste: ""
        });
      });
  };

  handleDelete = index => {
    base
      .collection("emails")
      .doc(index)
      .delete();
  };

  handleChangeName = event => {
    const value = event.target.value;
    this.setState(Object.assign({}, this.state, { name: value }));
  };

  handleChangeLastName = event => {
    const value = event.target.value;
    this.setState(Object.assign({}, this.state, { lastName: value }));
  };

  handleChangePoste = event => {
    const value = event.target.value;
    this.setState(Object.assign({}, this.state, { poste: value }));
  };

  componentWillMount() {
    const loggedIn = localStorage.getItem("isLoggedIn");

    if (loggedIn === "true") {
      this.setState({
        isLoggedIn: true
      });
    }
  }

  componentDidMount() {
    db.bindCollection("emails", {
      context: this,
      state: "mails",
      withIds: true,
      then(data) {
        this.setState(state => ({
          ...state,
          loading: false
        }));
      },
      onFailure(err) {
        throw new Error(err);
      }
    });
  }

  render() {
    return this.state.isLoggedIn ? (
      <section className="block-admin">
        <section className="admin__block-emails">
          <p className="block-admin__title">Liste des emails</p>
          {Object.keys(this.state.mails).map(mail => {
            const mailAlias = this.state.mails[mail];
            const fullName = mailAlias.name + " " + mailAlias.lastName;
            const poste = mailAlias.poste;

            return (
              <AdminSignature
                key={mail}
                handleDelete={() => this.handleDelete(mailAlias.id)}
                name={fullName}
                poste={poste}
              />
            );
          })}
        </section>
        <form
          className="block-admin__add"
          onSubmit={event => this.handleSubmit(event)}
        >
          <div className="full">
            <label>
              <span>Nom</span>
              <input
                type="text"
                onChange={event => this.handleChangeLastName(event)}
                value={this.state.lastName}
              />
            </label>
            <label>
              <span>Prénom</span>
              <input
                type="text"
                onChange={event => this.handleChangeName(event)}
                value={this.state.name}
              />
            </label>
          </div>
          <label>
            <span>Poste</span>
            <input
              type="text"
              onChange={event => this.handleChangePoste(event)}
              value={this.state.poste}
            />
          </label>
          <button type="submit" className="btn-submit">
            Ajouter
          </button>
        </form>
      </section>
    ) : (
      <Redirect
        to={{
          pathname: "/login"
        }}
      />
    );
  }
}

export default Admin;
