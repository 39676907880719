import React, { Component } from "react";
import Logo from "../../assets/images/email_logo.png";

export default class Signature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signature: this.props.match.params.id_signature,
      name: this.props.location.state.nom,
      lastName: this.props.location.state.lastName,
      poste: this.props.location.state.poste
        ? this.props.location.state.poste
        : ""
    };
  }
  render() {
    const fullName = this.state.name + " " + this.state.lastName;

    return (
      <>
        <table
          style={{
            width: "100%",
            maxWidth: "500px",
            margin: 0,
            paddingTop: "10px",
            marginBottom: "10px",
            fontFamily: "Arial, sans-serif",
            fontSize: "12px",
            display: "block",
            borderTop: "1px solid #bababa"
          }}
          width="500"
        >
          <tbody>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <img
                  alt="SCP Blanc-Grassin"
                  height="160"
                  src={Logo}
                  className="first-table__logo"
                />
              </td>
              <td
                style={{
                  fontSize: "1px",
                  lineHeight: "1px",
                  border: "0px",
                  padding: "0px",
                  width: "15px"
                }}
                width="20"
              />
              <td style={{ verticalAlign: "middle" }} width="230">
                <h2
                  style={{
                    color: "#11486f",
                    margin: 0,
                    fontSize: "16px",
                    fontWeight: "bold"
                  }}
                >
                  {fullName}
                </h2>
                <p
                  style={{
                    color: "#323232",
                    margin: 0,
                    marginTop: "3px",
                    fontSize: "11px"
                  }}
                >
                  {this.state.poste}
                </p>
                <p
                  style={{
                    color: "#000000",
                    margin: 0,
                    marginTop: "10px",
                    fontSize: "12px",
                    lineHeight: "1.2"
                  }}
                >
                  <a
                    href="tel:01 56 72 99 99"
                    style={{
                      color: "#000000",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      opacity: 0.7
                    }}
                  >
                    Tél: 01 56 72 99 99
                  </a>
                  <br />
                  <a
                    href="tel:01 42 07 49 09"
                    style={{
                      color: "#000000",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      opacity: 0.7
                    }}
                  >
                    Fax: 01 42 07 49 09
                  </a>
                  <br />
                  <a
                    href="tel:Mob: 06 64 96 22 12"
                    style={{
                      color: "#000000",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      opacity: 0.7
                    }}
                  >
                    Mob: 06 64 96 22 12
                  </a>
                </p>
                <a
                  href="tel:06 12 52 72 24"
                  style={{
                    color: "#cd4142",
                    margin: 0,
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    display: "block"
                  }}
                >
                  Urgence constat : 06 12 52 72 24
                </a>
                <p style={{ color: "#11486f", margin: 0, fontSize: "13px" }}>
                  <a href="mailto:contact@blancgrassin.com">
                    contact@blancgrassin.com
                  </a>
                  <br />
                  <a href="mailto:https://huissier-blanc-grassin.fr/">
                    https://huissier-blanc-grassin.fr
                  </a>
                  <br />
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                    lineHeight: "1.2"
                  }}
                >
                  <a
                    href="https://huissier-blanc-mesnil93.fr/"
                    style={{
                      fontWeight: "bold",
                      opacity: 0.7,
                      textDecoration: "underline"
                    }}
                    target="_blank noopener noreferrer"
                  >
                    Blanc-Mesnil
                  </a>
                  &nbsp;- Centre Aff Paris Nord Ampère
                  <br />
                  <a
                    href="https://boissy94-huissier.fr/"
                    style={{
                      fontWeight: "bold",
                      opacity: 0.7,
                      textDecoration: "underline"
                    }}
                    target="_blank noopener noreferrer"
                  >
                    Boissy
                  </a>
                  &nbsp;- 22 av. du Général Leclerc
                  <br />
                  <a
                    href="https://huissier-creteil.blanc-grassin.fr/"
                    style={{
                      fontWeight: "bold",
                      opacity: 0.7,
                      textDecoration: "underline"
                    }}
                    target="_blank noopener noreferrer"
                  >
                    Créteil
                  </a>
                  &nbsp;- 37 allée Parmentier &nbsp;
                  <br />
                  <a
                    href="https://melun.huissier-benoit.fr/"
                    style={{
                      fontWeight: "bold",
                      opacity: 0.7,
                      textDecoration: "underline"
                    }}
                    target="_blank noopener noreferrer"
                  >
                    Melun
                  </a>
                  &nbsp;- 5 Rue du Moulin de Poignet
                  <br />
                  <a
                    href="https://saintgermainenlaye.huissier-blanc-grassin.fr/"
                    style={{
                      fontWeight: "bold",
                      opacity: 0.7,
                      textDecoration: "underline"
                    }}
                    target="_blank noopener noreferrer"
                  >
                    St Germain
                  </a>
                  &nbsp;- 20-22 place Charles de Gaulle
                  <br />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}
