import React from "react";

const AdminSignature = ({ name, handleDelete, poste }) => {
  return (
    <article className="admin-signature">
      <div className="admin-signature__infos">
        <p className="infos__name">{name}</p>
        <p className="infos__poste">{poste}</p>
      </div>
      <div className="admin-signature__btn-delete" onClick={handleDelete}>
        <p>Supprimer --></p>
      </div>
    </article>
  );
};

export default AdminSignature;
