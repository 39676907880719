import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <section className="not-found">
      <p class="not-found__title">Bienvenue sur notre page 404 👋</p>
      <Link push to="/" className="not-found__subtitle">
        Retour à la page d'acceuil ?
      </Link>
    </section>
  );
};

export default NotFound;
