import firebase from "firebase/app";
import "firebase/firestore";
import Rebase from "re-base";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDVamDNqp09BNSC4A5fFdoRZ1qKVBR0sJc",
  authDomain: "mailsgrassin.firebaseapp.com",
  databaseURL: "https://mailsgrassin.firebaseio.com",
  projectId: "mailsgrassin"
});

export const base = firebaseApp.firestore();

const db = Rebase.createClass(base);

export default db;
